exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ai-service-ai-agents-js": () => import("./../../../src/pages/ai-service/ai-agents.js" /* webpackChunkName: "component---src-pages-ai-service-ai-agents-js" */),
  "component---src-pages-ai-service-ai-models-js": () => import("./../../../src/pages/ai-service/ai-models.js" /* webpackChunkName: "component---src-pages-ai-service-ai-models-js" */),
  "component---src-pages-ai-service-ai-platform-js": () => import("./../../../src/pages/ai-service/ai-platform.js" /* webpackChunkName: "component---src-pages-ai-service-ai-platform-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-microcms-resources-resources-id-js": () => import("./../../../src/pages/blog/{microcmsResources.resourcesId}.js" /* webpackChunkName: "component---src-pages-blog-microcms-resources-resources-id-js" */),
  "component---src-pages-casestudies-analytical-ai-js": () => import("./../../../src/pages/casestudies/analytical-ai.js" /* webpackChunkName: "component---src-pages-casestudies-analytical-ai-js" */),
  "component---src-pages-casestudies-data-structuring-js": () => import("./../../../src/pages/casestudies/data-structuring.js" /* webpackChunkName: "component---src-pages-casestudies-data-structuring-js" */),
  "component---src-pages-casestudies-geospatial-data-js": () => import("./../../../src/pages/casestudies/geospatial-data.js" /* webpackChunkName: "component---src-pages-casestudies-geospatial-data-js" */),
  "component---src-pages-casestudies-gx-solutions-js": () => import("./../../../src/pages/casestudies/gx-solutions.js" /* webpackChunkName: "component---src-pages-casestudies-gx-solutions-js" */),
  "component---src-pages-casestudies-js": () => import("./../../../src/pages/casestudies.js" /* webpackChunkName: "component---src-pages-casestudies-js" */),
  "component---src-pages-casestudies-optimization-ai-js": () => import("./../../../src/pages/casestudies/optimization-ai.js" /* webpackChunkName: "component---src-pages-casestudies-optimization-ai-js" */),
  "component---src-pages-casestudies-predictive-ai-js": () => import("./../../../src/pages/casestudies/predictive-ai.js" /* webpackChunkName: "component---src-pages-casestudies-predictive-ai-js" */),
  "component---src-pages-casestudy-microcms-casestudy-casestudy-id-js": () => import("./../../../src/pages/casestudy/{microcmsCasestudy.casestudyId}.js" /* webpackChunkName: "component---src-pages-casestudy-microcms-casestudy-casestudy-id-js" */),
  "component---src-pages-column-js": () => import("./../../../src/pages/column.js" /* webpackChunkName: "component---src-pages-column-js" */),
  "component---src-pages-column-microcms-resources-resources-id-js": () => import("./../../../src/pages/column/{microcmsResources.resourcesId}.js" /* webpackChunkName: "component---src-pages-column-microcms-resources-resources-id-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-human-rights-policy-js": () => import("./../../../src/pages/human-rights-policy.js" /* webpackChunkName: "component---src-pages-human-rights-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-construction-js": () => import("./../../../src/pages/industries/construction.js" /* webpackChunkName: "component---src-pages-industries-construction-js" */),
  "component---src-pages-industries-finance-js": () => import("./../../../src/pages/industries/finance.js" /* webpackChunkName: "component---src-pages-industries-finance-js" */),
  "component---src-pages-industries-food-js": () => import("./../../../src/pages/industries/food.js" /* webpackChunkName: "component---src-pages-industries-food-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-industries-manufacturing-js": () => import("./../../../src/pages/industries/manufacturing.js" /* webpackChunkName: "component---src-pages-industries-manufacturing-js" */),
  "component---src-pages-industries-public-js": () => import("./../../../src/pages/industries/public.js" /* webpackChunkName: "component---src-pages-industries-public-js" */),
  "component---src-pages-industries-retail-js": () => import("./../../../src/pages/industries/retail.js" /* webpackChunkName: "component---src-pages-industries-retail-js" */),
  "component---src-pages-interview-js": () => import("./../../../src/pages/interview.js" /* webpackChunkName: "component---src-pages-interview-js" */),
  "component---src-pages-interview-microcms-resources-resources-id-js": () => import("./../../../src/pages/interview/{microcmsResources.resourcesId}.js" /* webpackChunkName: "component---src-pages-interview-microcms-resources-resources-id-js" */),
  "component---src-pages-news-microcms-news-news-id-js": () => import("./../../../src/pages/news/{microcmsNews.newsId}.js" /* webpackChunkName: "component---src-pages-news-microcms-news-news-id-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-security-policy-js": () => import("./../../../src/pages/security-policy.js" /* webpackChunkName: "component---src-pages-security-policy-js" */),
  "component---src-pages-seminar-js": () => import("./../../../src/pages/seminar.js" /* webpackChunkName: "component---src-pages-seminar-js" */),
  "component---src-pages-seminar-microcms-resources-resources-id-js": () => import("./../../../src/pages/seminar/{microcmsResources.resourcesId}.js" /* webpackChunkName: "component---src-pages-seminar-microcms-resources-resources-id-js" */)
}

